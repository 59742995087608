import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { RouterModule } from '@angular/router';
import { TimeSelectComponent } from './time-select/time-select.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { AssignedSlideComponent } from './assined-slide/assigned-slide.component';
import { NotesComponent } from './notes/notes.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormControlPipe } from '../../dashboard/form-control.pipe';
import { MaskDirective } from '../directives/mask.directive';
import { ResetDateFieldDirective } from '../directives/reset-date-field.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AwesomeTooltipModule } from './awesome-tooltip/awesome-tooltip.module';

@NgModule({
  declarations: [ModalComponent, TimeSelectComponent, AssignedSlideComponent, NotesComponent],
  exports: [ModalComponent, TimeSelectComponent, AssignedSlideComponent, NotesComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgScrollbarModule,
    InfiniteScrollModule,
    FormControlPipe,
    MaskDirective,
    ResetDateFieldDirective,
    AwesomeTooltipModule,
  ],
  providers: [],
})
export class ComponentsModule {}
