export class GetAllMedicalApplication {
  static readonly type = '[Medical Application] Get all medical application';
}

export class ResetFilters {
  static readonly type = '[Medical Application] Reset Filters';
}

export class GetUserListToAssignMedicalForFilters {
  static readonly type = '[Medical Application] Get users to assigned medical application for filters';

  constructor(public search: string) {}
}

export class GetUserListToAssignMedical {
  static readonly type = '[Medical Application] Get users to assigned medical application';

  constructor(public search: string) {}
}

export class SetAssignUserToMedical {
  static readonly type = '[Medical Application] Assign user';

  constructor(
    public uid: string[],
    public assignIds: string[],
    public assignAllDocuments?: number,
  ) {}
}

export class DeleteUserToAssignMedical {
  static readonly type = '[Medical Application] Delete Assign user';

  constructor(
    public medicalId: string,
    public userId: string,
    public assignAllDocuments?: number,
  ) {}
}

export class GetStatusesMedicalApplication {
  static readonly type = '[Medical Application] Get statuses';
}

export class ChangeMedicalDates {
  static readonly type = '[Medical Application] Change dates ';

  constructor(
    public medicalId: string,
    public newDate: any,
    public typeDate: string,
  ) {}
}

export class EnableDatatableLoading {
  static readonly type = '[Medical Application] EnableDatatableLoading';
}
