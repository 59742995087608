export class GetUserListToAssignIncidents {
  static readonly type = '[Incidents] Get assign users';

  constructor(public search?: string) {}
}

export class GetUserListToAssignFilterIncidents {
  static readonly type = '[Incidents] Get assign users filter';

  constructor(public search?: string) {}
}

export class SetAssignUserToIncident {
  static readonly type = '[Incidents] Set assign users';

  constructor(
    public uid: string[],
    public assignIds: string[],
  ) {}
}

export class DeleteUserToAssignIncident {
  static readonly type = '[Incidents] Delete assign user';

  constructor(
    public incidentId: string,
    public userId: string,
  ) {}
}
