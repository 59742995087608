import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MedicalService } from '../../services/medical.service';
import {
  ChangeMedicalDates,
  DeleteUserToAssignMedical,
  EnableDatatableLoading,
  GetAllMedicalApplication,
  GetStatusesMedicalApplication,
  GetUserListToAssignMedical,
  GetUserListToAssignMedicalForFilters,
  SetAssignUserToMedical,
} from './medical-dashboard.actions';
import { convertMomentDate } from '../../../shared/helpers/date-format';
import { Observable, timer } from 'rxjs';
import { IncidentAttachmentsFilters } from '../filters/filters.models';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';

export interface MedicalDashboardModel {
  list: any[];
  attachmentsList: any[];
  page: number;
  editFormValue: any;
  total: number;
  totalAtt: number;
  usersList: any[];
  usersListFilter: any[];
  statuses: any[];
  meta: {
    isLoading: boolean;
  };
  random: {
    uid: string;
    password: string;
    autoLoginLink: string;
    loginPageLink: string;
  };
}

export const defaultState: MedicalDashboardModel = {
  list: [],
  attachmentsList: [],
  total: 0,
  totalAtt: 0,
  page: 1,
  editFormValue: {},
  usersList: [],
  usersListFilter: [],
  statuses: [],
  random: {
    uid: '',
    password: '',
    autoLoginLink: '',
    loginPageLink: '',
  },
  meta: {
    isLoading: true,
  },
};

@State({
  name: 'medicalDashboard',
  defaults: defaultState,
})
@Injectable()
export class MedicalDashboardState {
  @Selector()
  static medicalAppList(state: MedicalDashboardModel): any[] {
    return state.list;
  }

  @Selector()
  static isLoading(state: MedicalDashboardModel): boolean {
    return state.meta.isLoading;
  }

  @Selector()
  static usersToAssign(state: MedicalDashboardModel): any[] {
    return state.usersList;
  }

  @Selector()
  static usersToAssignFilter(state: MedicalDashboardModel): any[] {
    return state.usersListFilter;
  }

  @Selector()
  static statuses(state: MedicalDashboardModel): any[] {
    return state.statuses;
  }

  @Selector()
  static random(state: MedicalDashboardModel): any {
    return state?.random;
  }

  @Selector()
  static total(state: MedicalDashboardModel): number {
    return state.total;
  }

  @Selector()
  static totalAtt(state: MedicalDashboardModel): number {
    return state.totalAtt;
  }

  constructor(
    private service: MedicalService,
    private store: Store,
  ) {}

  @Action(GetAllMedicalApplication)
  getAllMedicalApps(ctx: StateContext<MedicalDashboardModel>): Observable<any> {
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
    const filtersValue: IncidentAttachmentsFilters = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.MedicalDashboard].model);
    const innerFilters: any = {
      ...filtersValue,
      dateFrom: filtersValue.date.from ? convertMomentDate(filtersValue.date.from) : null,
      dateTo: filtersValue.date.to ? convertMomentDate(filtersValue.date.to) : null,
    };
    delete innerFilters.date;
    return this.service.getMedicalAll({ ...innerFilters }).pipe(
      tap(({ data, total }) => {
        ctx.patchState({
          list: data,
          total,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(GetUserListToAssignMedicalForFilters)
  getUserListToAssign(ctx: StateContext<MedicalDashboardModel>, { search }: GetUserListToAssignMedicalForFilters): Observable<any> {
    return this.service.getUserList(search, 1).pipe(
      tap(users => {
        ctx.patchState({
          usersListFilter: users,
        });
      }),
    );
  }

  @Action(GetUserListToAssignMedical)
  getUserListToAssignMedical(ctx: StateContext<MedicalDashboardModel>, { search }: GetUserListToAssignMedical): Observable<any> {
    return this.service.getUserList(search, 0).pipe(
      tap(users => {
        ctx.patchState({
          usersList: users,
        });
      }),
    );
  }

  @Action(SetAssignUserToMedical)
  assignUserToMedical(ctx: StateContext<MedicalDashboardModel>, { uid, assignIds, assignAllDocuments }: SetAssignUserToMedical): Observable<any> {
    return this.service.assignedUser(uid, assignIds, assignAllDocuments);
  }

  @Action(DeleteUserToAssignMedical)
  deleteAssigned(ctx: StateContext<MedicalDashboardModel>, { medicalId, userId, assignAllDocuments }: DeleteUserToAssignMedical): Observable<any> {
    return this.service.deleteAssignedUser(medicalId, userId, assignAllDocuments);
  }

  @Action(GetStatusesMedicalApplication)
  getStatuses(ctx: StateContext<MedicalDashboardModel>): Observable<any> {
    return this.service.getStatusesForMedical().pipe(
      tap(value => {
        ctx.patchState({
          statuses: value,
        });
      }),
    );
  }

  @Action(ChangeMedicalDates)
  changeDates(ctx: StateContext<MedicalDashboardModel>, { medicalId, newDate, typeDate }: ChangeMedicalDates): Observable<any> {
    const date = convertMomentDate(newDate.selectedDates[0]);
    return this.service.changeDocumentDate(medicalId, date, typeDate);
  }

  @Action(EnableDatatableLoading)
  enableDatatableLoading(ctx: StateContext<any>): void {
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
  }

  private disableDataTableLoading(ctx: StateContext<any>): void {
    timer(1000)
      .pipe(take(1))
      .subscribe((): void => {
        ctx.patchState({
          meta: {
            isLoading: false,
          },
        });
      });
  }
}
