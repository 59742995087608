<div [class.full]="!editableNote">
  <div class="slide-container__body">
    <div (click)="cancelNotes()" class="close">
      <i class="icon-close"></i>
    </div>
    <div
      (scrolled)="onScrollDown()"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      class="slide-container__content"
      infiniteScroll>
      <div *ngIf="editableNote; else list" class="create-notes">
        <div class="slide-container__title">{{ editableNote?.id ? 'edit' : 'create' }} note</div>
        <div [formGroup]="noteForm" class="create-notes__form">
          <div
            [class.error]="noteErrors?.violations?.reason && !noteForm.controls.reason.value"
            class="create-notes__form-item">
            <label class="select">
              <span>Reasons</span>
              <select formControlName="reason">
                <option *ngIf="(reason$ | async).length" [value]="null" disabled selected>Select reason</option>
                <option *ngFor="let reason of reason$ | async" [value]="reason.id">{{ reason.title }}</option>
              </select>
            </label>
            <div *ngIf="noteErrors?.violations?.reason && !noteForm.controls.reason.value" class="error-msg">
              {{ noteErrors.violations.reason }}
            </div>
          </div>
          <div class="create-notes__form-item">
            <label class="create-notes__form-title" id="note">Notes</label>
            <textarea
              (keydown)="changeNote()"
              [class.error]="noteErrors?.violations?.text &&
                             (!noteForm.controls.text.value ||
                               noteForm.controls.text.value.length >= 2000 ||
                               noteForm.controls.text.value.length < 2)"
              formControlName="text">
              </textarea>
            <div
              *ngIf="noteErrors?.violations?.text &&
                         (!noteForm.controls.text.value ||
                         noteForm.controls.text.value.length >= 2000 ||
                         noteForm.controls.text.value.length < 2)"
              class="error-msg">
              {{ noteErrors.violations.text }}
            </div>
          </div>
          <div class="create-notes__form-btn">
            <button (click)="cancelNotes(true)" class="cancel">Cancel</button>
            <button (click)="saveNotes()" [disabled]="disabledSave" class="btn violet">
              Save
            </button>
          </div>
        </div>
      </div>
      <ng-template #list>
        <div class="notes-list">
          <div class="slide-container__title">notes</div>
          <div class="notes-list__add">
            <button
              (click)="newNote()"
              *appPermission="[PermissionAdmissionEnum.CreateNote]"
              class="btn violet new-note">
              <span><i class="icon-plus"></i> Add Note</span>
            </button>
          </div>
          <div class="notes-list__data">
            <div *ngFor="let note of selectedAdmission?.notes" class="notes-list__item">
              <div class="item-top-row">
                <div class="notes-list__item-name">{{ note.author }}</div>
                <div
                  *appPermission="[PermissionAdmissionEnum.UpdateNote]"
                  class="notes-list__item-controls">
                  <button (click)="updateNote(note)" class="edit">
                    <i class="icon-edit"></i>
                  </button>
                </div>
              </div>
              <div class="notes-list__item-date">{{ note.updatedAt | date: 'MM/dd/yyyy h:mm a' }}</div>
              <div *ngIf="note?.reason?.title" class="notes-list__item-option">{{ note?.reason?.title }}</div>
              <p class="item-text">{{ note.text }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>>
