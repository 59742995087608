import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IncidentsService } from '../../../services/incidents.service';
import {
  DeleteUserToAssignIncident,
  GetUserListToAssignFilterIncidents,
  GetUserListToAssignIncidents,
  SetAssignUserToIncident,
} from './incidents-assign-users.actions';
import { tap } from 'rxjs/operators';
import { EmployeesStateModel } from '../../employees/employees.state';
import { Observable } from 'rxjs';

export const defaultState = {
  usersList: [],
  usersListFilter: [],
};

@State({
  name: 'incidentsAssignUsers',
  defaults: defaultState,
})
@Injectable()
export class IncidentsAssignUsersState {
  constructor(private api: IncidentsService) {}

  @Selector()
  static usersToAssign(state): any {
    return state.usersList;
  }

  @Selector()
  static usersToAssignFilter(state): any {
    return state.usersListFilter;
  }

  @Action(GetUserListToAssignIncidents)
  setUserListToAssignIncidents(ctx: StateContext<any>, { search }: GetUserListToAssignIncidents): Observable<any> {
    return this.api.getUserListToAssign(search, 0).pipe(
      tap(users => {
        ctx.patchState({
          usersList: users,
        });
      }),
    );
  }

  @Action(GetUserListToAssignFilterIncidents)
  setUserListToAssignFilterIncidents(ctx: StateContext<any>, { search }: GetUserListToAssignFilterIncidents): Observable<any> {
    return this.api.getUserListToAssign(search, 1).pipe(
      tap(users => {
        ctx.patchState({
          usersListFilter: users,
        });
      }),
    );
  }

  @Action(SetAssignUserToIncident)
  setAssignUserToIncidents(ctx: StateContext<any>, { uid, assignIds }: SetAssignUserToIncident): Observable<any> {
    return this.api.setAssignUserToIncidents(uid, assignIds);
  }

  @Action(DeleteUserToAssignIncident)
  deleteAssigned(ctx: StateContext<EmployeesStateModel>, { incidentId, userId }: DeleteUserToAssignIncident): Observable<void> {
    return this.api.deleteAssignedUser(incidentId, userId);
  }
}
